<template>
    <div class="ls-product-card">
      <router-link :to="{ name: 'request_quotation'}">
        <div class="row">
          <div class="col-12"><img v-lazy="product.images[0].path" /></div>
          <div class="col-12">
            <a class="ps-product__vendor">
              {{ product.name }}
            </a>
          </div>
          <div class="col-12">
            <router-link :to="{ name: 'request_quotation'}">
              <h4>{{ product.shop.name }}</h4>
            </router-link>
          </div>
        </div>
      </router-link>
      <div class="col-12">
        <button class="btn ls-button" @click="requestQuote()">
          Request Quote
        </button>
      </div>
    </div>
  </template>
  <script>
  import global from "@/mixins/global.js";
  import { mapState } from "vuex";
  import axios from "axios";
  export default {
    props: ["product"],
    data() {
      return {
        loading: false,
        inCart: false,
      };
    },
    computed: {
      imagePath() {
        if (this.product.images && this.product.images.length > 0) {
          return this.product.images[0].path;
        } else {
          return "";
        }
      },
      ...mapState({
        auth: (state) => state.auth,
        cart: (state) => state.cart,
      }),
      productColors() {
        // console.log(this.product.properties);
        // return this.product.properties.filter(prop => prop.color)
      },
      productSizes() {
        // console.log(this.product.properties);
        // return this.product.properties.filter(prop => prop.size)
      },
    },
    mixins: [global],
    mounted() {
      // console.log("Cart", this.cart.cartItems);
      this.cart.cartItems.forEach(product => {
        if (product.id === this.product.id) {
          this.inCart = true;
        }
      });
    },
    methods: {
      requestQuote() {
        this.$router.push({ name: "request_quotation" });
      },
    },
  };
  </script>
  