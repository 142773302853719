<template>
  <div class="ps-page--single ps-page--vendor">
    <section class="ps-store-list">
      <div class="ps-container">
        <div class="w-100 text-center">
          <img class="shop-image-banner" :src="shop.image_path" alt="" />
        </div>
        <div class="ps-layout--shop">
          <div class="ps-layout__left">
            <aside class="widget widget_shop">
              <h4 class="widget-title">Filter by categories</h4>
              <ul class="ps-list--categories">
                <li
                  v-for="category in shop_categories"
                  :key="category.id"
                  class="menu-item-has-children"
                >
                  <label class="checkboxWrapper">
                    <input
                      type="checkbox"
                      :value="category.name"
                      class="checkbox"
                      @change="handleCategories(category.name)"
                    />
                    {{ category.name }}
                  </label>
                </li>
              </ul>
            </aside>
          </div>
          <div class="ps-layout__right">
            <div class="ps-section__wrapper">
              <div class="ps-section__right" style="">
                <div class="ps-shopping ps-tab-root">
                  <div v-if="count > 0" class="ps-shopping__header">
                    <p v-if="categoryFilters.length === 0 && !isLoading">
                      Showing <strong>{{ products.length }}</strong> of
                      <strong> {{ count }}</strong> Products
                    </p>
                    <p v-else-if="categoryFilters.length > 0 && !isLoading">
                      Showing
                      <strong>{{ filteredProducts.length }}</strong> under
                      <strong v-for="category in categoryFilters"
                        >{{ category }},
                      </strong>
                    </p>
                    <div class="ps-shopping__actions">
                      <div class="ps-shopping__view">
                        <p>View</p>
                        <ul class="ps-tab-list">
                          <li id="icon_grid" class="active">
                            <a href="#tab-1" @click.prevent="gridView()"
                              ><i class="icon-grid"></i
                            ></a>
                          </li>
                          <li id="icon_list">
                            <a href="#tab-2" @click.prevent="listView()"
                              ><i class="icon-list4"></i
                            ></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="ps-tabs">
                    <div class="ps-tab active" id="tab-1">
                      <div class="ps-shopping-product">
                        <div class="row">
                          <div
                            v-for="product in categoryFilters.length > 0
                              ? filteredProducts
                              : products"
                            :key="product.id"
                            class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6"
                          >
                            <Product-card :product="product"></Product-card>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="ps-tab" id="tab-2">
                      <div class="ps-shopping-product">
                        <list-product-card
                          v-for="product in categoryFilters.length > 0
                            ? filteredProducts
                            : products"
                          :key="product.id"
                          :product="product"
                        ></list-product-card>
                      </div>
                    </div>

                    <div class="table-responsive mb-3">
                      <table class="table ps-table ps-table--vendor">
                        <tfoot>
                          <tr v-if="(!meta || meta.total === 0) && !isLoading">
                            <td
                              colspan="7"
                              class="text-center m-4 text-danger p-4"
                            >
                              No Listings available
                            </td>
                          </tr>
                          <tr v-else-if="isLoading">
                            <td colspan="7" class="text-center m-4 text-danger">
                              Loading listings...
                            </td>
                          </tr>
                          <tr v-else>
                            <td class="text-right" colspan="12">
                              <ul
                                class="pagination text-right"
                                style="position: relative"
                              >
                                <li
                                  v-on:click="goToPrevPage()"
                                  v-if="hasPrevPage"
                                >
                                  <a href="#"
                                    ><i class="icon-chevron-left"></i
                                  ></a>
                                </li>
                                <li class="active">
                                  <a href="#">Page {{ pageNumber }} </a>
                                </li>
                                <li
                                  v-for="link in next3Links"
                                  v-on:click="goToPage(link.url)"
                                >
                                  <a href="#">{{ link.page }} </a>
                                </li>
                                <li
                                  v-on:click="goToPage(lastPage)"
                                  v-if="hasNextPage"
                                >
                                  <a href="#">...{{ totalPages }}</a>
                                </li>
                                <!--                      <li><a href="#">3</a></li>-->
                                <li
                                  v-on:click="goToNextPage()"
                                  v-if="hasNextPage"
                                >
                                  <a href="#"
                                    ><i class="icon-chevron-right"></i
                                  ></a>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h5 style="margin-top: -77px; font-weight: 2000" class="ls-center">
            {{ pageTitle }}
          </h5>
          <nav class="ph-quick-links ps-store-link">
            <div class="row ls-center">
              <div class="col-3">
                <a href="pahukama-supermarket">
                  <img src="/img/groceries.png" />
                  <p>Groceries</p>
                </a>
              </div>
              <div class="col-3">
                <a href="pahukama-butchery">
                  <img src="/img/butchery.png" />
                  <p>Butchery</p>
                </a>
              </div>
              <div class="col-3">
                <a href="pahukama-liquor">
                  <img src="/img/liquor.png" />
                  <p>Liquor Store</p>
                </a>
              </div>
              <div class="col-3">
                <a href="pahukama-bakery">
                  <img src="/img/bakery.png" />
                  <p>Bakery</p>
                </a>
              </div>
              <div class="col-3">
                <a href="pahukama-greens">
                  <img src="/img/vegitables.png" />
                  <p>Greens</p>
                </a>
              </div>
              <div class="col-3">
                <a href="pahukama-hardware">
                  <img src="/img/hardware.png" />
                  <p>Hardware</p>
                </a>
              </div>
              <div class="col-3">
                <a href="/get-runner">
                  <img src="/img/sweets.png" />
                  <p>Treats</p>
                </a>
              </div>
              <div class="col-3">
                <a href="pahukama-takeaways">
                  <img src="/img/giftset.png" />
                  <p>Takeaways</p>
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ProductCard from "@/components/Cards/ProductCard.vue";
import TreatCard from "@/components/Cards/TreatCard.vue";
import ListProductCard from "@/components/Cards/ListProductCard.vue";
import axios from "axios";
import global from "@/mixins/global";
import { first } from "lodash";
export default {
  components: { ProductCard, ListProductCard, TreatCard },
  mixins: [global],
  data() {
    return {
      showMyDiv: true,
      shop: {},
      storeCategories: [],
      products: [],
      updatedProducts: [],
      isLoading: false,
      meta: null,
      isLoading: false,
      links: {},
      url: null,
      hasNextPage: false,
      nextPage: null,
      hasPrevPage: false,
      previousPage: null,
      pageNumber: 1,
      count: 0,
      totalPages: 0,
      envUrl: process.env.VUE_APP_API_URL,
      next3Links: [{ url: "", page: "" }],
      lastPage: null,
      firstPage: null,
      shop_categories: [],
      categoryFilters: [],
      updatedCategoryFilters: [],
      filteredProducts: [],
    };
  },
  computed: {
    shouldShowDiv() {
      // Return a boolean indicating whether the div should be shown or not
      return this.currentPage !== "valentine";
    },
    pageTitle() {
      // Check the current route and return the appropriate page title
      if (this.$route.name === "shop") {
        return "Continue Shopping from any of our Stores";
      } else {
        return "Continue Shopping";
      }
    },
  },

  mounted() {
    this.isLoading = true;
    this.getShop();

    if (this.$route.name === "shop") {
      this.showMyDiv = false;
    }
  },
  watch: {
    $route(to, from) {
      //get shop products when link changes
      this.getShop();
    },
  },
  methods: {
    needQuote(product) {
      var shopArray = ["pahukama-treats", "pahukama-township"];

      if (shopArray.includes(product.shop.slug) && product.price == "1.00") {
        return true;
      } else {
        return false;
      }
    },
    listView() {
      if (document.getElementById("tab-2").classList.contains("active")) {
        return "";
      } else {
        document.getElementById("tab-1").classList.remove("active");
        document.getElementById("tab-2").classList.add("active");
        document.getElementById("icon_list").classList.add("active");
        document.getElementById("icon_grid").classList.remove("active");
      }
    },

    gridView() {
      if (document.getElementById("tab-1").classList.contains("active")) {
        return "";
      } else {
        document.getElementById("tab-2").classList.remove("active");
        document.getElementById("tab-1").classList.add("active");
        document.getElementById("icon_grid").classList.add("active");
        document.getElementById("icon_list").classList.remove("active");
      }
    },

    getShop() {
      var that = this;
      axios.get(`shops/${this.$route.params.slug}`).then((response) => {
        that.shop = response.data;
        this.getShopProducts(that.shop.id);
        //this.getShopCategories(this.shop.category.id);
        this.isLoading = false;
      });
    },
    getShop2(url) {
      var that = this;
      axios.get(`shops/${this.$route.params.slug}`).then((response) => {
        that.shop = response.data;
        this.getShopProducts2(that.shop.id);
        //this.getShopCategories(this.shop.category.id);
        this.isLoading = false;
      });
    },

    handleCategories(categoryName) {
      if (this.categoryFilters.includes(categoryName)) {
        this.categoryFilters = this.categoryFilters.filter(
          (name) => name !== categoryName
        );
      } else {
        this.categoryFilters.push(categoryName);
      }
      if (this.categoryFilters.length > 0) {
        this.filteredProducts = this.products.filter((product) =>
          this.categoryFilters.includes(product.category.name)
        );
      } else {
        this.filteredProducts = this.products;
      }
    },

    getShopCategories(shopCategoryId) {
      axios
        .get(`categories?shopcategory=${shopCategoryId}`)
        .then((response) => {
          this.storeCategories = response.data;
        });
    },

    getShopCategories2() {
      axios.get(`categories`).then((response) => {
        this.storeCategories = response.data;
      });
    },

    getShopProducts(shopId) {
      this.shop_categories = [];
      this.filteredProducts = [];
      this.products = [];
      axios.get(`products?shop=${shopId}`).then((response) => {
        let oldProducts = response.data.data;
        this.products.length = 0;
        oldProducts.forEach((product) => {
          if (product.images && product.images.length > 0) {
            this.products.push(product);
          }
          if (
            !this.shop_categories.some(
              (category) => category.name == product.category.name
            )
          ) {
            this.shop_categories.push(product.category);
          }
        });
        if (this.categoryFilters.length > 0) {
          this.filteredProducts = this.products.filter((product) =>
            this.categoryFilters.includes(product.category.id)
          );
        }

        this.meta = response.data.meta;
        this.links = response.data.links;
        this.count = response.data.meta.total;
        //this.count = this.products.length;
        this.pageNumber = response.data.meta.current_page;
        this.handleNextPage(response.data.links);
        this.handlePrevPage(response.data.links);
      });
      this.products.forEach((product) => {});
    },

    getShopProducts2(shopId) {
      //Next page products
      axios.get(this.url + "&shop=" + shopId).then((response) => {
        let oldProducts = response.data.data;
        this.products.length = 0;
        oldProducts.forEach((product) => {
          if (product.images && product.images.length > 0) {
            this.products.push(product);
          }
        });
        this.meta = response.data.meta;
        this.links = response.data.links;
        this.count = response.data.meta.total;
        //this.count = this.products.length;
        this.pageNumber = response.data.meta.current_page;
        this.handleNextPage(response.data.links);
        this.handlePrevPage(response.data.links);
      });
    },

    goToNextPage: function () {
      this.url = this.nextPage;
      if (this.url) {
        //this.getShop2(this.url);
        this.getShopProducts2(this.shop.id);
      }
      //this.scrollTop();
    },
    goToPage: function (url) {
      this.url = url;
      if (this.url) {
        //this.getShop2(this.url);
        this.getShopProducts2(this.shop.id);
      }
      //this.scrollTop();
    },

    goToPrevPage: function () {
      this.url = this.prevPage;
      if (this.url) {
        //this.getShop2(this.url);
        this.getShopProducts2(this.shop.id);
      }
      //this.scrollTop();
    },

    handleNextPage(links) {
      if (links.next) {
        this.hasNextPage = true;
        this.nextPage = links.next;
        this.totalPages = parseInt(links.last.split("=")[1]);
        this.lastPage = links.last;
        this.firstPage = links.first;
        //populate next 3 links with the next 3 pages after the current page with keys url: (${envUrl}/products?page=${i}) and page: i
        this.next3Links = [];

        for (
          let i = this.pageNumber + 1;
          i <= this.pageNumber + 3 && i < this.totalPages;
          i++
        ) {
          this.next3Links.push({
            url: `${this.envUrl}products?page=${i}`,
            page: i,
          });
        }
      } else {
        this.hasNextPage = false;
        this.next3Links = [];
      }
    },

    handlePrevPage(links) {
      if (links.prev) {
        this.hasPrevPage = true;
        this.prevPage = links.prev;
      } else {
        this.hasPrevPage = false;
      }
    },
  },
};
</script>
<style scoped>
.ph-quick-links p {
  color: black;
}
.ph-quick-links {
  background-color: #fda31e;
}

.ph-quick-links img {
  max-width: 50px;
  width: 100%;
}
.row-2 {
  background-color: white;
  margin-top: -50px;
  margin-bottom: 0px;
}
.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  cursor: pointer;
}
.checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  outline: none;
}
.checkbox:checked {
  background-color: #000;
}
.checkbox:checked::before {
  content: "\2713";
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  right: 5px;
  top: 0;
}
</style>
